@import url('https://fonts.googleapis.com/css2?family=Mukta:wght@200;300;400;500;600;700;800&display=swap');
:root{
  --blue:#3778B8;
  --blue-1:#D4F6FF4D;
  --blue-2:#D8E6F3;
  --dark:#212121;
  --brand-color-black: #333;
  --pink:#C83898;
  --pink-1:#C8389811;
  --orange:#F79F28;
  --orange-1:#FFF3D4;
  --white:#ffffff;
  --light:#D9D9D9;
  --grey: #999999;
  --grey-2: #666666;
  --br:0.5rem;
  --br-sm:0.25rem;
  --br-lg:0.75rem;
}
html{
  scroll-behavior: smooth;
}
body{
  font-family: "Mukta", sans-serif;
  font-weight: 400;
  line-height: 2;
  background: linear-gradient(180deg, rgba(225, 231, 237, 0.2) 13.75%, rgba(55, 120, 184, 0.094964) 45.5%, rgba(55, 120, 184, 0) 62%, rgba(55, 120, 184, 0.2) 96%);
}
h1,h2,h3,h4,h5,h6,.btn{
  font-weight: 700;
}
a{
  text-decoration: none;
}
nav .nav-item{
  margin-left: 1rem;
  font-weight: 400;
}
nav .nav-link:not(.btn){
  color: var(--brand-color-black) !important;
  font-weight: 400;
}
.dropdown:hover .dropdown-menu{
  display: block;
}
.dropdown-menu{
  border: none !important;
  box-shadow: 0 0 10px #0002;
  border-radius: var(--br) !important;
  overflow: hidden;
  padding: 0 0 !important;
}
.close-mobile-menu{
  display: none;
}
.text-blue{
  color: var(--blue);
}
.text-pink{
  color: var(--pink);
}
.text-orange{
  color: var(--orange);
}
.text-dark {
  color: var(--dark);
}
.fw-400{
  font-weight: 400 !important;
}
.fw-500{
  font-weight: 500 !important;
}

.btn{
  font-size: 1rem;
  font-weight: 400 !important;
  padding: 0.6rem 1.5rem !important;
}
.btn-login,.btn-white{
  background-color: #E0F7FF !important;
  color: var(--blue) !important;
}
.btn-main,.update-btns .btn-outline-main{
  background-color: var(--blue) !important;
  color: var(--white) !important;
  border: 1px solid var(--blue) !important;
}
.btn-orange{
  background-color: var(--orange);
  color: var(--white);
}
.btn-white{
  background-color: var(--white);
  color: var(--blue) !important;
}
.btn-block{
  display: block;
  width: 100%;
  text-align: center;
}
.btn-outline-main{
  color: var(--blue) !important;
  background-color: var(--white) !important;
  border: 1px solid var(--blue);
}
.btn-pink{
  background-color: var(--pink);
  color: var(--white);
  border: 1px solid var(--pink);
}
.btn-outline-pink{
  border: 1px solid var(--pink);
  background-color: var(--white);
  color: var(--pink);
}
.btn-white-pink{
  color: var(--pink);
  background-color: var(--white);
}
.course-cta{
  position: relative;
  border-radius: 0  0 20rem 20rem;
  /* overflow: hidden; */
  background-color: #fff5;
  min-height: 300px;
}
.course-cta::before{
  content: '';
  background-image: url('../public/img/course-cta.png');
  height: 100%;
  width: 100%;
  border-radius: inherit;
  top: 10px;
  left: 10px;
  position: absolute;
}
.home-courses .row .col-md-4:nth-child(3n - 2) .text-theme{
  color: var(--pink);
}
.home-courses .row .col-md-4:nth-child(3n - 1) .text-theme{
  color: var(--orange);
}
.home-courses .row .col-md-4:nth-child(3n) .text-theme{
  color: var(--blue);
}
.blog-list-page .row{
  flex-direction: column;
}
.blog-list-page .col-md-3,.blog-list-page .col-md-9{
  width: 100%;
}
.blog-list-page .col-md-9 {
  margin-top: 1rem;
}
.single-article-img{
  border-radius: var(--br);
  margin: 1rem 0;
}
.contact-info-card{
  display: flex;
  gap:1rem;
}
.contact-info-icon{
  font-size: 2rem;
  width: 4rem;
  height: 4rem;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #3778B822;
  border-radius: var(--br-sm);
}
.contact-info-content{
  width: calc(100% - 5rem);
}
.author-prof-img{
  height: 60px;
  width: 60px;
  object-fit: cover;
  object-position: center;
  border-radius: 50%;
}
.form-submit-success{
  z-index: 10000;
  position: fixed;
  height: 100vh;
  width: 100vw;
  background-color: #0003;
  backdrop-filter: blur(15px);
  left: 0;
  top: 0;
  display: flex;
  align-items: center;
  justify-content: center;

}
.thank-you-inner{
  width: 400px;
  max-width: 100%;
  background-color: var(--white);
  border-radius: var(--br);
  box-shadow: 0 0 15px #0002;
  padding: 2rem;
  text-align: center;
}
.form-submit-success h3{
  font-size: 2.25rem;
  color: var(--blue);
}

.hero-section{
  position: relative;
  z-index: 1;
  position: relative;
  padding: 5rem 0 10rem !important;
}
.hero-section .btn{
  margin-right: 1rem;
}
.hero-section *{
  position: relative;
  z-index: 10;
}
.hero-section::after{
  content: '';
  background-image: url('../public/img/HeroImage.png');
  background-size: cover;
  background-position: left;
  background-repeat: no-repeat;
  position: absolute;
  right: 0;
  top: 5rem;
  height: 75%;
  border-radius: 40rem 0 0 40rem;
  width: calc(50% - 3rem);
}
.hero-section::before{
  content: '';
  right: 0;
  z-index: -2;
  bottom: 0;
  width: 55%;
  height: 70%;
  background-color: var(--blue-1);
  position: absolute;
  border-radius: 40rem 0 0 40rem;
}
.hero-section h1{
  font-size:72px;
}
.hero-styled-p{
  text-transform: uppercase;
  position: relative;
  color: var(--orange);
  margin: 2rem 0;
  padding-left: 4rem;
}
.hero-section .home-desc{
  font-size: 22px;
}
.hero-styled-p::before{
  content: '';
  height: 2px;
  background-color: var(--orange);
  position: absolute;
  width: 3rem;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
  display: block;
}
.vector-1,.vector-2,.vector-3{
  display: block;
  border-radius: 100%;
  aspect-ratio: 1/1;
  position: absolute;
  animation: breath 3s infinite;
}
.vector-1{
  height: 3rem;
  width: 3rem;
  background-color: var(--blue);
  animation-delay: 1s;
}
.vector-2{
  animation-delay: 2s;
}
.vector-2,.vector-3{
  height: 3.25rem;
  width: 3.25rem;
  background-color: var(--pink);
}
.vector-3{
  background-color: var(--orange);
}
.hero-section .vector-1{
  left: -60px;
  bottom: -1rem;
}
.hero-section .vector-2{
  left: 0.75rem;
  bottom: -1rem;
}
.hero-section .vector-3{
  left: 1rem;
  bottom: -5rem;
}
.about-hero-section .vector-1{
  left: calc(50% + 1rem);
  top: 3rem;
}
.about-hero-section .vector-2{
  left: calc(50% - 2rem);
  top: 3rem;
}
.about-hero-section .vector-3{
  left: calc(50% - 1rem);
  top: 7rem;
}
.newsletter .vector-1,.course-hero-section .vector-1{
  left: calc(60% - 1rem);
  bottom: 2rem;
}
.newsletter .vector-2,.course-hero-section .vector-2{
  left: calc(60% - 3rem);
  bottom: 6rem;
}
.newsletter .vector-3,.course-hero-section .vector-3{
  left: calc(60% - 6rem);
  bottom: 4rem;
}

section{
  position: relative;
}
.cta-section .vector-1{
  left: calc(50% - 5rem);
  top: 5rem;
}
.cta-section .vector-2{
  left: calc(50% - 8rem);
  top: 1rem;
}
.cta-section .vector-3{
  left: calc(50% - 4rem);
  top: 1rem;
}
.site-card{
  box-shadow: 0 0 10px #0001;
  border-radius: var(--br-lg);
  background-color: var(--white);
  position: relative;
  padding: 1.5rem;
  z-index: 1;
}
.site-card h3{
  font-size: 1.1rem;
}
.site-card .fancy-card-title{
  position: relative;
  padding-left: 1.25rem;
  margin-bottom: 1rem;
}
.site-card .fancy-card-title::before{
  content: '';
  height: 100%;
  width: 2px;
  background-color: currentColor;
  left: 0;
  top: 0;
  position: absolute;
}
.site-card p{
  font-size: 0.9rem;
}
.home-why-us-card{
  padding: 2rem 2rem 0.25rem;
  margin-top: -2.5rem;
  position: relative;
  z-index: 10;
}
.fancy-heading{
  position: relative;
  padding-bottom: 1rem;
  margin-bottom: 1rem;
}
.fancy-heading::before{
  content: '';
  left: 0;
  height: 1px;
  width: 3rem;
  position: absolute;
  bottom: 0;
  background-color: var(--blue);
}
.fancy-orange::before{
  background-color: var(--orange);
}
.why-us-card img{
  width: 60%;
  margin-bottom: 1.5rem;
}
.why-us-card h3{
  font-size: 1.1rem;
  color: var(--blue);
}
.why-us-card p{
  max-width: 80%;
  font-size: 0.9rem;
}
.why-us-section{
  position: relative;
  /* overflow: hidden; */
}
.why-us-section::before{
  content: '';
  height: 40rem;
  width: 40rem;
  max-width: 100%;
  aspect-ratio: 1/1;
  position: absolute;
  left: -10rem;
  bottom: 15rem;
  border-radius: 100%;
  background-color: var(--blue-1);
}
.why-us-section::after{
  content: '';
  height: 22.5rem;
  width: 22.5rem;
  max-width: 50%;
  aspect-ratio: 1/1;
  position: absolute;
  right: 0;
  bottom: 5rem;
  border-radius: 100%;
  background-color: var(--orange-1);
}
.lc-1,.lc-2,.lc-3,.lc-4,.lc-5,.lc-6{
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;  
  overflow: hidden;
}
.lc-1{
  -webkit-line-clamp: 1;
}
.lc-2{
  -webkit-line-clamp: 2;
}
.lc-3{
  -webkit-line-clamp: 3;
}
.lc-4{
  -webkit-line-clamp: 4;
}
.lc-5{
  -webkit-line-clamp: 5;
}
.lc-6{
  -webkit-line-clamp: 6;
}
.cta-section{
  position: relative;
}
.cta-section::before{
  content: '';
  width: calc(50% - 3rem);
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  background-image: url('../public/img/cta.png');
  background-size: cover;
  background-position: left center;
  background-repeat: no-repeat;
  border-radius: 0 40rem 40rem 0;
}
.min-height{
  min-height: 440px;
}
.testimonials{
  background-image: url('../public/img/testimonials-bg.png');
  padding: 3.5rem 0 !important;
  background-size: cover;
  /* margin-top: 5rem; */
  background-color: var(--blue-1);
  background-blend-mode: multiply;
  position: relative;
  min-height: 1000px;
  display: flex;
  align-items: center;
}
.testimonials::before{
  content: '';
  left: 0;
  top: 50%;
  position: absolute;
  /* height: calc(100% - 7rem); */
  min-height: 550px;
  width: 80%;
  background-color: var(--blue);
  transform: translateY(-50%);
  border-radius: 0 var(--br-lg) var(--br-lg) 0;
}
.course-testimonials.testimonials::before{
  background-color: var(--pink) !important;
}
.testimonial-vectors{
  position: absolute;
  bottom: 0;
  right: 0;
}
.testimonial-vectors .v1,.testimonial-vectors .v2,.testimonial-vectors .v3{
  height: 2rem;
  position: absolute;
  right: 0;
  display: block;
  border-radius: var(--br) 0 0 var(--br);
}
.testimonial-vectors .v1{
  width: 3rem;
  background-color: var(--pink);
  bottom: 8rem;
}
.testimonial-vectors .v2{
  width: 5rem;
  background-color: var(--orange);
  bottom: 5rem;
}
.testimonial-vectors .v3{
  width: 4rem;
  background-color: var(--blue);
  bottom: 2rem;
}
.testimonials p,.testimonials h2{
  position: relative;
}
.testimonial-card{
  background-color: var(--white);
  box-shadow: 0 0 8px #0002;
  border-radius: var(--br);
  overflow: hidden;
  font-size: 0.85rem;
}
.testimonial-card .prof-img{
  width: 100%;
  max-height: 140px;
  overflow: hidden;
  object-fit: cover;
  object-position: top;
}
.testimonial-card .company-img{
  max-width: 50px;
  max-height: 20px;
  text-align: left;
  object-fit: contain;
}
.testimonial-content{
  padding: 1rem;
}
.linkedin-icon{
  height: 1.75rem;
  width: 1.75rem;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: -2rem;
  font-size: 0.8rem;
  background-color: var(--blue);
  line-height: 1;
  color: var(--white);
  position: relative;
  border-radius: 50%;
}
.testimonial-card h5{
  font-size: 1rem;
}
.carousel-indicators [data-bs-target] {
  width: 6px;
  height: 6px;
  border-radius: 50%;
}
.carousel-control-prev i{
  margin-bottom: -1px;
}
.carousel-nav-icon{
  height: 1.5rem;
  font-size: 0.8rem;
  width: 2rem;
  display: flex;
  justify-content: center;
  align-items: center;
  color: var(--white);
  border: 1px solid;
  position: absolute;
  border-radius: var(--br-sm);
}
.carousel-control-prev .carousel-nav-icon{
  left: 0;
}
.carousel-control-next .carousel-nav-icon{
  right: 0;
  color: var(--blue);
}
.stat-card{
  text-align: center;
}
.stat-card h3{
  font-size: 4rem;
  color: var(--blue);
}
.newsletter{
  background-color: #E0F7FF;
  background-image: url('../public/img/cta-bg.svg');
  background-size: cover;
}
.newsletter .form-control{
  /* border-radius: 2rem; */
  padding-left: 1rem;
  padding-top: 0.7rem;
  padding-bottom: 0.7rem;
  box-shadow: none;
  border-color: var(--blue) !important;
  border: none;
  background: #3778B81A !important;

}
.course-ul{
  padding-left: 1rem;
  display: grid;
  grid-template-columns: 1fr 1fr;
  line-height: 2;
}
.bg-pink{
  background-color: var(--pink);
  color: var(--white);
}
.course-hero-section{
  padding: 3rem 0 !important;
  min-height: calc(100vh - 200px);
  align-items: center;
  display: flex;
}
.course-hero-section h1{
  font-size: 72px;
  color: var(--brand-color-black) !important;
}
.course-hero-section .btn-theme{
  background-color: var(--blue) !important;
}
.job-profile{
  height: 4rem;
  width: 4rem;
  align-items: center;
  justify-content: center;
  display: flex;
  border: 1px solid var(--white);
  border-radius: 100%;
  padding: 1rem;
}
.job-profile img{
  width: 75%;
  filter: brightness(0) invert(1);
}
.job-description ul{
  font-size: 0.9rem;
  padding-left: 1rem;
  line-height: 2.5;
}
.accordion-item{
  border-radius: var(--br) !important;
  box-shadow: 0 0 10px #0002;
  overflow: hidden;
  margin-bottom: 1rem;
  border: none !important;;
}
.accordion-item:last-of-type{
  margin-bottom: 0;
}
.site-accordion{
  background-color: transparent !important;
  border-bottom: none !important;
  position: relative;
}
.accordion-button{
  box-shadow: none !important;
  position: relative !important;
}
.accordion-button:not(.collapsed){
  color: var(--blue) !important;
}
.site-accordion::before{
  content: '\2715';
  position: absolute;
  right: 1rem;
  top: 50%;
  transform: rotate(45deg) translateY(-50%);
  color: var(--pink);
  font-size: 1rem;
  line-height: 0;
  font-size: 1rem;
  transition: 0.5s all ease;
  font-weight: 200;
}
.site-accordion:not(.collapsed)::before{
  transform-origin: center;
  transform: translateY(-50%);
}
.accordion-body{
  padding-top: 0 !important;
}
.accordion-button::after{
  height: 1rem;
  width: 1rem;
  display: none;
  background-size: 1rem;
}
.site-accordion-title{
  font-size: 1rem;
  font-weight: 500;
  display: block;
  line-height: 1.5;
}
.accordion-small{
  display: block;
  font-size: 0.75rem;
  margin-top: 0.25rem;
}
.course-tutors-section::before{
  background-image: url('../public/img/course-tutors.png');
}
.course-tutors-section-outer{
  background-image: url('../public/img/course-tutors.svg');
  background-size: cover;
  padding: 7rem 0;
  background-color: #FCFCFC;
  background-blend-mode: multiply;
  margin-top: 2rem;
  position: relative;
}
.course-tutors-section-outer .v1,
.course-tutors-section-outer .v2,
.course-tutors-section-outer .v3{
  height: 3rem;
  width: 3rem;
  display: block;
  border-radius: 50%;
  position: absolute;
  top: 2rem;
  z-index: 1;
  transform: translateX(-75%);
}
.course-tutors-section-outer .v1{
  background-color: var(--pink);
  left: calc(25% - 6rem);
}
.course-tutors-section-outer .v2{
  background-color: var(--orange);
  left:  calc(25% - 2rem);
  opacity: 0.4;
}
.course-tutors-section-outer .v3{
  background-color: var(--blue);
  left: calc(25% + 2rem);
  opacity: 0.5;
}
.bg-blue-1{
  background-color: var(--blue-1);
}
.bg-pink-1{
  background-color: var(--pink-1);
}
.custom-tab-pills .nav-item{
  margin-right: 1rem;
}
.custom-tab-pills .nav-link{
  padding: 0.75rem 1.25rem;
  line-height: 1;
  border-radius: 2rem !important;
  background-color: var(--blue-2);
  color: var(--blue) !important;
}
.custom-tab-pills .nav-link.active{
  background-color: var(--blue) !important;
  color: var(--light) !important;
}
.article-list{
  margin-bottom: 1rem;
}
.article-list .blog-img-box{
  max-height: 10rem;
  overflow: hidden;
  border-radius: var(--br);
}
.article-list .blog-img-box img{
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
}
.article-list h2{
  font-size: 1.25rem;
}
.about-cta .bg-pink{
  background-color: var(--blue);
}
.team-card{
  border-radius: var(--br);
  border: 1px solid var(--blue);
  overflow: hidden;
}
.team-img{
  text-align: center;
  max-height: 200px;
  overflow: hidden;
  border-bottom: 1px solid var(--blue);
}
.team-img img{
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: top;
}
.team-footer{
  padding: 1rem 1.5rem;
}
.contact-card{
  box-shadow: 0 0 15px #0002 !important;
}
.tuition-card{
  text-align: center;
}
.swiper {
  width: 100%;
  display: block;
  padding: 1rem;
}
.article-article{
  white-space: pre-wrap;
}

/* Theme Pink */
.theme-pink .bg-theme{
  background-color: var(--pink);
  color: var(--white);
}
.theme-pink .testimonial-theme::before{
  background-color: var(--pink);
}
.theme-pink .btn-theme{
  background-color: var(--pink);
  color: var(--white);
}
.theme-pink .text-theme{
  color: var(--pink);
}
.theme-pink .theme-accordion .site-accordion::before{
  color: var(--pink);
}
.theme-pink .theme-card{
  border: 1px solid var(--pink);
}
.theme-pink .btn-outline-theme{
  border: 1px solid var(--pink);
  color: var(--pink);
}
.theme-pink + footer{
  background-color: var(--pink);
}

.theme-orange + footer{
  background-color: var(--orange);
}

/* Theme Orange */
.theme-orange .bg-theme{
  background-color: var(--orange);
  color: var(--white);
}
.theme-orange .testimonial-theme::before{
  background-color: var(--orange);
}
.theme-orange .btn-theme{
  background-color: var(--orange);
  color: var(--white);
}
.theme-orange .text-theme{
  color: var(--orange);
}
.theme-orange .theme-accordion .site-accordion::before{
  color: var(--orange);
}
.theme-orange .theme-card{
  border: 1px solid var(--orange);
}
.theme-orange .btn-outline-theme{
  border: 1px solid var(--orange);
  color: var(--orange);
}


/* Theme Blue */
.theme-blue .bg-theme{
  background-color: var(--blue);
  color: var(--white);
}
.theme-blue .testimonial-theme::before{
  background-color: var(--blue);
}
.theme-blue .btn-theme{
  background-color: var(--blue);
  color: var(--white);
}
.theme-blue .text-theme{
  color: var(--blue);
}
.theme-blue .theme-accordion .site-accordion::before{
  color: var(--blue);
}
.theme-blue .theme-card{
  border: 1px solid var(--blue);
}
.theme-blue .btn-outline-theme{
  border: 1px solid var(--blue);
  color: var(--blue);
}
.nav-badge{
  background-color: var(--orange);
  font-size: 0.85rem;
  line-height: 1.2 !important;
  display: inline-block;
  padding: 0.125rem 0.25rem;
  border-radius: 0.25rem;
  color: var(--white);
}
.custom-dropdown{
  position: relative;
  padding-right: 1.5rem !important;
}
.custom-dropdown::before{
  content: '';
  height: 8px;
  width: 8px;
  border-bottom: 1px solid var(--blue);
  border-right: 1px solid var(--blue);
  position: absolute;
  right: 0;
  top: calc(50% - 4px);
  transform: translateY(-50%) rotate(45deg);
}
.dark-c-img{
  text-align: center;
}
.dark-c-img img{
  max-width: 100%;
  max-height: 40px;
  object-fit: contain;
  border-radius: var(--br);
}


.toTop{
  background-color: var(--blue-1);
  color: var(--blue);
  padding: 0.5rem 1rem !important;
  font-size: 0.9rem !important;
}
.dark-container{
  background-color: var(--dark);
  padding: 2rem;
  border-radius: var(--br-lg);
  text-align: center;
}
.dark-container h2{
  color: var(--white);
  font-size: 1.5rem;
}
.course-list-new-card{
  color: var(--white);
}
.home-courses .col-md-4:nth-child(1) .course-list-new-card{
  background-color: var(--pink);
}
.home-courses .col-md-4:nth-child(2) .course-list-new-card{
  background-color: var(--orange);
}
.home-courses .col-md-4:nth-child(3) .course-list-new-card{
  background-color: var(--blue);
}
.course-list-new-card p{
  font-size: 1rem !important;
  line-height: 2 !important;
}
.course-list-new-card h3{
  color: var(--white) !important;
  font-size: 2rem;
}
.course-card-btn{
  background-color: var(--white);
  width: 100%;
  position: relative;
  display: block;
  padding: 0.5rem 1rem;
  border-radius: var(--br-sm);
}
.course-card-btn i{
  position: absolute;
  right: 1rem;
  top: 50%;
  transform: translateY(-50%);
}
.home-courses .col-md-4:nth-child(1) .course-card-btn{
  color: var(--pink);
}
.home-courses .col-md-4:nth-child(2) .course-card-btn{
  color: var(--orange);
}
.home-courses .col-md-4:nth-child(3) .course-card-btn{
  color: var(--blue);
}
.tuition-card{
  padding: 1.5rem;
  text-align: center;
  border-radius: var(--br-lg);
  background: linear-gradient(56.56deg, rgba(140, 69, 117, 0.1) 1.95%, rgba(240, 40, 174, 0.089) 94.03%);
  min-height: 240px;
  align-items: center;
  display: flex;
  height: 100%;
}
.tuition-card.popular-tc{
  background: linear-gradient(56.56deg, #8C4575 1.95%, rgba(240, 40, 174, 0.89) 94.03%);
  color: var(--white);
}
.rounded-custom{
  border-radius: var(--br-lg);
}
.tc-desc{
  font-size: 1rem;
}
.tc-price{
  font-size: 2rem;
  font-weight: 700;
}
.tc-price .small-small{
  font-size: 1rem;
}
.btn-gradient{
  background: linear-gradient(56.56deg, #8C4575 1.95%, rgba(240, 40, 174, 0.89) 94.03%);
  color: var(--white);
}
.call-to-action {
  background-image: url('../public/img/cta-bg.svg');
  background-color: var(--pink);
  background-size: cover;
  /* background-blend-mode: multiply; */
}
.blog-pagination{
  display: flex;
  gap: 1rem;
  align-items: center;
}
.pagination-nav{
  display: inline-block;
  font-weight: 500;
  color: #212121;
  cursor: pointer;
}
.pagination-nav.disabled{
  color: #888;
  cursor: not-allowed;
}
.pagination-numbers{
  display: flex;
  gap: 1rem;
  align-items: center;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
}
.pagination-numbers li{
  display: flex;
  height: 2rem;
  width: 2rem;
  font-size: 0.85rem;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  background-color: var(--blue-2);
}
.pagination-numbers li:is(.active,:hover){
  background-color: var(--blue);
  color: var(--white);
}
.ellipsis{
  background-color: transparent !important;
  pointer-events: none;
  letter-spacing: 2px;
}
.blog-breadcrumbs{
  display: flex;
  list-style: none;
  align-items: center;
  justify-content: center;
  width: 100%;
  gap: 2rem;
  padding-bottom: 0;
  margin-left: 0;
}
.blog-breadcrumbs li{
  position: relative;
}
.blog-breadcrumbs li::before{
  content: '';
  height: 0.35rem;
  width: 0.35rem;
  border-right: 1px solid var(--brand-color-black);
  border-bottom: 1px solid var(--brand-color-black);
  transform: translateY(-50%) rotate(-45deg);
  position: absolute;
  right: -1rem;
  top: 50%;
}
.blog-breadcrumbs li:last-of-type::before{
  display: none;
}
.article-heading-group{
  margin-bottom: 2rem;
}
.article-toc{
  list-style: none;
  padding-left: 0;
  margin-bottom: 0;
}
.article-toc li{
  line-height: 2;
  color: var(--grey);
  cursor: pointer;
  position: relative;
  padding-left: 1.5rem;
  margin-bottom: 0.5rem;
}
.article-toc li::before{
  content: '';
  position: absolute;
  height: 100%;
  width: 4px;
  left: 0;
  background-color: var(--grey);
}
.article-toc :is(.active,:hover){
  color: var(--brand-color-black);
}
.article-toc :is(.active,:hover)::before{
  background-color: var(--orange);
}
.article-shares{
  display: flex;
  gap: 1rem;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
  margin-left: -0.25rem;
}
.article-shares li{
  height: 1.75rem;
  width: 1.75rem;
}
.article-shares li img{
  width: 100%;
  height: 100%;
}
.other-article-card{
  border-radius: var(--br-lg);
  overflow: hidden;
  box-shadow: 0 0 10px #0002;
  border: 2px solid var(--white);
}
.other-article-img{
  height: 250px;
  position: relative;
}
.other-article-img::before{
  content: '';
  height: 100%;
  width: 100%;
  position: absolute;
  left: 0;
  top: 0;
  background-color: #212121;
  opacity: 0.5;
}
.other-article-img img{
  height: 100%;
  width: 100%;
  object-fit: cover;
  object-position: center;
  display: block;
}
.other-article-content{
  padding: 1.25rem 1.5rem;
}
.other-article-content h3{
  font-size: 1.125rem;
  line-height: 1.5;
}
.other-article-content .lc-3{
  line-height: 1.6;
  color: var(--grey);
  margin-bottom: 0.25rem;
}
.oac-category{
  display: inline-block;
  color: var(--white);
  background-color: #fff4;
  position: absolute;
  top: 1rem;
  left: 1rem;
  padding: 0.5rem 1rem;
  line-height: 1;
  border-radius: 2rem;
  font-weight: 500;
  backdrop-filter: blur(20px);
}
.contact-us-card{
  background-color: var(--white);
  position: relative;
  padding: 3rem;
  border-radius: var(--br-lg);
  padding-top: 320px;
}
.contact-us-card::before{
  content: '';
  height: 250px;
  background-image: url('../public/img/contact-us-top.jpeg'), url('../public/img/contact-us-top-2.png');
  background-size: cover;
  background-position: center;
  background-blend-mode: multiply;
  width: calc(100% + 5rem);
  left: -2.5rem;
  position: absolute;
  border-radius: var(--br-lg);
  top: 2.5rem;
}
.contact-card-inner{
  position: relative;
}
.contact-card-inner::before,
.contact-card-inner::after{
  content: '';
  height: 100%;
  width: 80px;
  position: absolute;
  top: 0;
  background-size: cover;
  filter: grayscale(1);
  background-position: center;
}
.contact-card-inner::before{
  background-image: url('../public/img/contact-form-before.svg');
  left: 3rem;
}
.contact-card-inner::after{
  background-image: url('../public/img/contact-form-after.svg');
  right: 3rem;
}
.form-new-contact-inner{
  width: 550px;
  max-width: 100%;
  margin: 4rem auto 1rem;
  position: relative;
  /* padding: 0.5rem; */
}
.new-contact-form{
  width: 100%;
  background-color: var(--brand-color-black);
  color: var(--grey);
  padding: 2rem;
  border-radius: var(--br-lg);
  position: relative;
  z-index: 2;
}
.form-new-contact-inner::before,.form-new-contact-inner::after{
  content: '';
  position: absolute;
  width: 60px;
  height: 90px;
  border-radius: calc(var(--br-lg) + 0.25rem);
  position: absolute;
  background-color: var(--pink);
}
.form-new-contact-inner::before{
  left: -0.4rem;
  top: -0.4rem;
}
.form-new-contact-inner::after{
  right: -0.4rem;
  bottom: -0.4rem;
}
.new-contact-form .form-control{
  background-color: var(--grey);
  border: none;
  box-shadow: none;
  border: none;
  padding: 0.5rem 0.75rem !important;
}
.contact-us-page-favicon{
  position: absolute;
  height: 190px;
  width: 140px;
  top: 80px;
  right: 160px;
}
.contact-info-flex{
  display: flex;
  gap: 0.5rem;
  align-items: center;
}
.cif-img{
  width: 1rem;
}
.cif-info{
  width: calc(100% - 1.5rem);
  font-weight: 700;
}
.cif-img img{
  width: 100%;
}
.contact-page-contact-group{
  margin-bottom: 1rem;
}
.contact-page-contact-group small{
  color: var(--grey-2);
}
.contact-page-social-icons{
  display: flex;
  gap: 1rem;
  background-color:#F2F1F1;
  list-style: none;
  padding-left: 0;
  padding: 0.75rem;
  border-radius: var(--br);
  justify-content: space-between;
  max-width: 200px;
}
.contact-page-social-icons img{
  height: 24px;
  width: 24px;
  display: block;
}
.add-row-between{
  position: relative;
}
.add-row-between::before{
  content: '';
  height: calc(100% - 5rem);
  width: 1px;
  background-color: var(--pink);
  position: absolute;
  left: 40%;
  top: 50%;
  transform: translate(-50%, -50%);
  opacity: 0.5;
}
.code-buds-hero-section{
  padding: 3.5rem 0 !important;
  background-size: cover;
  min-height: 500px;
  background-color: var(--blue-1);
  background-blend-mode: multiply;
  position: relative;
  display: flex;
  align-items: center;
  background-color: #C8389880;
  background-image: linear-gradient(270deg, rgba(0, 0, 0, 0) 0%, rgba(255, 255, 255, 0.71) 48%),
  url('../public/img/code-buds.png');
  background-position: center;
  background-blend-mode: color;
  background-color: #8C95A04F;
  font-weight: 500;
}
.code-buds-hero-section *{
  position: relative;
}
.code-buds-hero-section h1{
  font-size: 64px;
  font-weight: 700;
}
.code-buds-hero-section::before{
  content: '';
  height: 100%;
  width: 100%;
  position: absolute;
  left: 0;
  top: 0;
  background-image: linear-gradient(270deg, rgba(0, 0, 0, 0) 0%, rgba(255, 255, 255, 0.71) 48%);
}
.code-buds-hero-section::after{
  content: '';
  height: 100%;
  width: 50%;
  background-image: url('../public/img/code-buds-after.svg');
  background-size: 100%;
  background-position: right;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  position: absolute;
  background-repeat: no-repeat;
}
.code-buds-hero-section .vector-1{
  left: -20px;
  bottom: -6rem;
  z-index: 10;
}
.code-buds-hero-section .vector-2{
  left: 1rem;
  bottom: -6rem;
  z-index: 10;
}
.code-buds-hero-section .vector-3{
  left: -2rem;
  bottom: -7rem;
  z-index: 10;
}
.code-buds-icon-card{
  background-color: #F9F8F8;
  padding: 1.5rem;
  border-radius: var(--br-lg);
  text-align: center;
  font-weight: 600;
}
.cbic-icon{
  height: 120px;
  width: 120px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto 1rem;
  padding: 2rem;
  border-radius: 50%;
}
.cbic-icon img{
  height: 100%;
  width: 100%;
  object-fit: contain;
}
.pink{
  background-color: #F4E4EF;
}
.blue{
  background-color: #E8E9F6;
}
.green{
  background-color: #E3EFDE;
}
.orange{
  background-color: #FCEBD1;
}
.fancy-icon{
  position: absolute;
  left: 1rem;
  top: -1rem;
  height: 80px;
  width: 80px;
}
.program-card{
  border-radius: var(--br-lg);
  overflow: hidden;
  background-color: var(--white);
  box-shadow: 0 0 10px #0001;
}
.program-card-img{
  height: 250px;
}
.program-card-img img{
  height: 100%;
  width: 100%;
  object-fit: cover;
  object-position: center;
}
.program-content{
  padding: 1rem 1.5rem 1.5rem;
}
.program-content h3{
  font-size: 1.5rem;
  line-height: 1.4;
}
.inline-tabs{
  display: block;
  margin-bottom: 0.25rem;
}
.custom-badge{
  display: inline-block;
  margin: 0.25rem 0.5rem 0.25rem 0;
  font-size: 12px;
  padding: 0.25rem 0.5rem;
  line-height: 1.3;
  border-radius: var(--br-sm);
}
.badge-blue{
  background-color: var(--blue);
  color: var(--white);
}
.badge-grey{
  color: var(--brand-color-black);
  background-color: #E7E7E7;
}
.bg-fancy-orange{
  background-color: var(--orange);
  background-image: url('../public/img/cta-bg.svg');
  background-size: cover;
}
.stat-white-card{
  background-color: var(--white);
  box-shadow: 0 0 10px #0002;
  padding: 2rem;
  border-radius: var(--br-lg);
}

.theme-orange .call-to-action{
  background-color: var(--orange) !important;
}
.theme-orange .btn.btn-white-pink{
  color: var(--orange);
}

.theme-blue .call-to-action{
  background-color: var(--blue) !important;
}
.theme-blue .btn.btn-white-pink{
  color: var(--blue);
}
.quote-box{
  width: 800px;
  max-width: 100%;
  margin: 0 auto;
  text-align: center;
  position: relative;
  padding-top: 3rem;
}
.quote-box::before{
  content: '\201C';
  font-size: 6rem;
  line-height: 1;
  color: var(--pink);
  position: absolute;
  left: 50%;
  top: 0;
}
.quote-box p{
  font-weight: 600;
  font-size: 36px;
  line-height: 1.4;
}
.cod-bud-img{
  width: 80px;
  height: 80px;
  margin: 0 auto 2rem;
  border-radius: 50%;
  overflow: hidden;
  background-color: var(--white);
  border: 3px solid var(--white);
  box-shadow: 0 0 10px #0001;
}
.cod-bud-img img{
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
  border-radius: 50%;
}
.code-bud-stars{
  color: var(--pink);
  letter-spacing: 6px;
  font-size: 0.95rem;
  margin-top: 1rem;
}
.code-bud-testimonial-card{
  width: 500px;
  margin: 0 auto;
  max-width: 100%;
  font-size: 1.125rem;
}

footer{
  font-size: 0.95rem;
  line-height: 2;
  background-color: var(--blue);
  color: var(--white);
}
footer ul{
  list-style: none;
  margin-top: 1rem;
  padding-left: 0;
}
footer a{
  color: var(--dark);
}
footer a:hover{
  color: var(--blue);
}
.dropdown-menu{
  padding: 1rem !important;
  min-width: 240px;
}
.dropdown-menu .dropdown-item{
  position: relative;
  border-radius: var(--br-sm);
}
.dropdown-menu .dropdown-item i{
  opacity: 0;
  position: absolute;
  right: 1rem;
  top: 50%;
  transform: translateY(-50%);
}
.dropdown-menu .dropdown-item:hover{
  color: var(--blue);
  background-color: #3778B822 !important;
}
.dropdown-menu .dropdown-item:hover i{
  opacity: 1;

}
.footer-icons{
  display: flex;
  gap: 1rem;
  align-items: center;
  margin-top: 0;
}
.footer-logo{
  height: 24px;
  width: 24px;
  display: block;
}
.footer-new-socials{
  list-style: none;
  display: flex;
  gap: 1rem;
  padding-left: 0;
  padding-bottom: 0;
  margin-bottom: 0;
  background: #fff2;
  padding: 0.5rem 0.5rem !important;
  border-radius: var(--br);
  align-items: center;
  margin-top: 0 !important;
}
.footer-new-socials a{
  line-height: 1;
  color: var(--white) !important;
}
.footer-new-socials a i{
  line-height: 1;
  /* margin-bottom: 0 !important; */
  display: block;
}
.footer-terms{
  display: flex;
  gap: 2rem;
  align-items: center;
  padding-left: 0;
  justify-content: flex-end;
  margin-bottom: 0 !important;
  margin-top: 0 !important;
}
.footer-terms a{
  color: var(--white) !important;
}
.new-team-card{
  background-color: var(--blue-1);
  padding: 2rem;
  border-radius: 10px;
  display: flex;
  gap: 2rem;
}
.new-team-card p{
  margin-bottom: 0;
}
.team-about-sect{
  width: calc(100% - 232px);
}
.team-image-sec{
  width: 200px;
  height: 100%;
  height: 340px;
  background-color: var(--white);
  border-radius: 8px;
  overflow: hidden;
  position: relative;
  border: 1px solid var(--white);
}
.team-image-sec img{
  width: 100%;
  object-fit: cover;
  height: 100%;
  object-position: center;
  display: block;
}
.team-card-info{
  padding: 0.75rem 1rem;
  position: absolute;
  width: 100%;
  z-index: 2;
  height: max-content;
  background-color: var(--white);
  left: 0;
  bottom: 0;
}
.team-card-info h3{
  font-size: 1.125rem;
  line-height: 1;
  margin-bottom: 0;
}
.team-card-info small{
  font-style: italic;
  color: var(--blue);
}
.team-card-info a{
  position: absolute;
  right: 1rem;
  top: 0.75rem;
  color: var(--blue);
}
.testimonialSwipper .swiper-slide{
  margin-right: 1.5rem;
}
@keyframes breath {
  0%{
    transform: scale(1);
  }
  20%{
    transform: scale(0.75);
  }
  40%{
    transform: scale(0.5);
  }
  80%{
    transform: scale(0.75);
  }
  100%{
    transform: scale(1);
  }
}

@media screen and (min-width:1000px){
  .team-section .team-card-row:nth-child(even) .team-row-img{
    order:2;
  }
  .team-section .team-card-row:nth-child(even) .team-row-description{
    order:1;
  }
  .testimonialSwipper .testimonial-card{
    max-width: 214px;
    margin: 0 !important;
  }
  .testimonialSwipper .swiper-slide{
    max-width: 215px;
  }
}

@media screen and (max-width:999px) {
  .navbar-collapse.collapse,.navbar-collapse.collapsing{
      height: 100vh !important;
      padding-right: 1rem;
      position: fixed !important;
      display: block;
      width: 200px;
      max-width: 100%;
      top: 0;
      background-color: #0008;
      backdrop-filter: blur(10px);
      -webkit-backdrop-filter: blur(10px);
      transition: 0.25s ease left;
      transform-origin: left;
      left: -100%;
  }
  .navbar-collapse .nav-link,.dropdown-menu .nav-link{
      color: var(--white) !important;
      font-weight: 600 !important;
  }
  .dropdown-menu{
    box-shadow: none !important;
  }
  .navbar-collapse .btn{
      margin-bottom: 0.5rem;
  }
  .navbar-collapse.collapsing{
      left: -50%;
      transition: none;
  }
  .navbar-collapse.show,.navbar-collapse.collapsing{
      left: 0;
  }
  .navbar-toggler{
      border: none;
      box-shadow: none !important;
      outline: none;
      position: relative;
  }
  .bar-1,.bar-2,.bar-3{
      height: 2px;
      margin-left: auto;
      display: block;
      background-color: var(--brand-color-black);
  }
  .bar-1{
      width: 1rem;
  }
  .bar-2{
      width: 1.5rem;
      margin-top: 0.5rem;
      margin-bottom: 0.5rem;
  }
  .bar-3{
      width: 1rem;
  }
  .close-mobile-menu{
      height: 2rem;
      width: 2rem;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 2rem;
      line-height: 1;
      background-color: transparent;
      box-shadow: none;
      border: none;
      outline: none;
      color: var(--white);
      font-weight: 400;
      position: absolute;
      right: 0.25em;
      top: 0.25rem;
      z-index: 10;
  }
  .why-us-card p{
      max-width: 100%;
  }
  .why-us-section{
      max-width: 100vw;
      /* overflow-x: hidden; */
  }
  .why-us-card img{
      width: 100%;
  }
  .course-ul{
      grid-template-columns: 1fr;
  }
  .dropdown-menu{
      padding: 0;
      border: none !important;
      background-color: transparent;
  }
  .dropdown-item{
      padding-left: 0 !important;
      color: var(--white) !important;
  }
  .btn-main,.btn-outline-main,.update-btns .btn-outline-main{
      background-color: var(--blue) !important;
      color: var(--white) !important;
      border: 1px solid var(--blue) !important;
  }
  .carousel-item .row .col-6:last-of-type{
      display: none;
  }
  .testimonials{
    min-height: unset;
  }
  .testimonials::before{
      width: 100%;
      height: 100%;
      opacity: 0.7;
      top: 0;
      transform: unset;
      border-radius: 0;
  }
  .course-hero-section{
      min-height: unset;
  }
  .about-hero-section :is(.vector-1,.vector-2,.vector-3){
      display: none;
  }
  .hero-styled-p{
    padding-left: 0;
  }
  .hero-styled-p::before{
    width: 2rem;
    position: relative;
    left: 50%;
    top: -1rem;
    transform: translateX(-50%);
  }
  .stat-white-card{
    background-color: transparent;
    padding: 0;
    box-shadow: none;
  }
  .sm-pink-bg{
    background-color: var(--pink);
    color: var(--white) !important;
    padding: 0.5rem 0.75rem;
    border-radius: var(--br-lg);
  }
  .sm-pink-bg p{
    margin-bottom: 0;
    font-size: 12px;
  }
  .sm-pink-bg h3{
    color: var(--white);
    font-size: 2rem;
  }
  .stat-white-card .text-muted{
    color: var(--white) !important;
  }
  .footer-icons{
    width: 100%;
    margin-top: 1rem;
  }
  .footer-new-socials{
    width: 100%;
    justify-content: space-evenly;
  }
  .footer-terms{
    justify-content: flex-start;
    margin: .75rem 0 !important;
  }
  .navbar-light .navbar-nav .nav-link:not(.btn){
    color: var(--white) !important;
  }
  .navbar-light .navbar-nav .nav-link.btn-white{
    color: var(--blue) !important;
  }
  .course-hero-section h1{
    font-size: 36px;
  }
  .course-hero-section{
    padding: 1.5rem 0 !important;
  }
  .new-team-card{
    flex-direction: column;
  }
  .team-image-sec,.team-about-sect{
    width: 100%;
  }
  .quote-box p{
    font-size: 20px;
    line-height: 1.6;
  }
  .contact-us-card{
    padding-top: 160px !important;
  }
  .code-buds-hero-section{
    min-height: unset;
    padding: 1.5rem 0 !important;
  }
  .code-buds-hero-section h1{
    font-size: 32px;
  }
  .code-buds-hero-section::after{
    display: none;
  }
  .fancy-icon{
    height: 40px;
    width: 40px;
    left: 1rem;
  }
  .code-buds-hero-section .vector-1,
  .code-buds-hero-section .vector-2,
  .code-buds-hero-section .vector-3{
    left: 0;
  }
  .cbic-icon{
    height: 80px;
    width: 80px;
    padding: 1rem;
    margin-bottom: 0.5rem;
  }
  .code-buds-icon-card{
    height: 100%;
    padding: 1rem;
  }
  .code-buds-icon-card p{
    font-size: 0.9rem;
    margin-bottom: 0;
  }
}
@media screen and (max-width:767px){
  
  .hero-section{
      padding: 1rem 0 4rem !important;
      text-align: center;
  }
  .hero-section::after{
      display: none;
  }
  .hero-section h1{
      font-size: 2rem;
  }
  .vector-1{
      height: 2rem;
      width: 2rem;
  }
  .vector-2{
      height: 1.5rem;
      width: 1.5rem;
  }
  .vector-3{
      height: 1rem;
      width: 1rem;
  }
  .hero-section .vector-1{
      left: unset;
      right: 3rem;
      bottom: 2rem;
  }
  .hero-section .vector-2{
      left: unset;
      right: 4rem;
      bottom: 5rem;
  }
  .hero-section .vector-3{
      left: unset;
      right: 2rem;
      bottom: 5rem;
  }
  .cta-section{
      padding-top: 15rem !important;
  }
  .cta-section::before{
      width: 100%;
      height: 13rem;
      top: 0;
      border-radius: 0 0 0 0;
  }

  .cta-section .vector-1{
      left: calc(50% - 5rem);
      top: 4rem;
  }
  .cta-section .vector-2{
      left: calc(50% - 7rem);
      top: 2rem;
  }
  .cta-section .vector-3{
      left: calc(50% - 7rem);
      top: 4rem;
  }
  .min-height{
      min-height: unset;
  }
  .testimonials{
      margin-top: 1rem;
  }
  .carousel-item .row .col-6{
      padding-bottom: 2rem;
  }
  .carousel-control-prev,.carousel-control-next{
      display: none;
  }
  .testimonial-vectors .v1,.testimonial-vectors .v2,.testimonial-vectors .v3{
      height: 1rem;
      border-radius: var(--br-sm) 0 0 var(--br-sm);
  }
  .testimonial-vectors .v1{
      width: 2rem;
      background-color: var(--pink);
      bottom: 5rem;
  }
  .testimonial-vectors .v2{
      width: 3rem;
      background-color: var(--orange);
      bottom: 3rem;
  }
  .testimonial-vectors .v3{
      width: 2.5rem;
      background-color: var(--blue);
      bottom: 1rem;
  }
  .course-tutors-section-outer{
      padding: 4rem 0 1rem;
  }
  .course-tutors-section-outer .v1,
  .course-tutors-section-outer .v2,
  .course-tutors-section-outer .v3{
      height: 1.75rem;
      width: 1.75rem;
      top: 1rem;
  }
  .course-tutors-section-outer .v1{
      background-color: var(--pink);
      left: calc(25% - 3rem);
  }
  .course-tutors-section-outer .v2{
      background-color: var(--orange);
      left:  calc(25%);
      opacity: 0.4;
  }
  .course-tutors-section-outer .v3{
      background-color: var(--blue);
      left: calc(25% + 3rem);
      opacity: 0.5;
  }
  .course-cta::before{
      width: calc(100% - 1.25rem);
      height: calc(100% - 1.25rem);
  }
  .custom-tab-pills .nav-item{
      margin-right: 0.25rem;
      margin-bottom: 0.25rem;
  }
  .custom-tab-pills .nav-link{
      padding: 0.5rem 1rem;
  }
  .blog-breadcrumbs{
    justify-content: flex-start;
  }
  
  .contact-us-card::before{
    height: 120px;
    width: 100%;
    left: 0;
    top: 0;
  }
  .contact-us-page-favicon{
    height: 70px;
    width: 50;
    top: 60px;
    right: 20px;
  }
  .add-row-between::before{
    display: none;
  }
  .contact-us-card{
    padding: 1rem 0.5rem;
  }
  .contact-page-contact-group{
    margin-bottom: 0.5rem;
  }
  .form-new-contact-inner{
    margin: 1rem auto 0;
  }
  .contact-card-inner::before,
  .contact-card-inner::after{
    display: none;
  }
}